<template>
  <validation-observer ref="simpleRules">
    <component>
      <b-card v-if="session > 0">
        <div class="d-flex">
          <feather-icon
            icon="PlusCircleIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50"> Mapping Bisnis Unit, Divisi dan Segmen User : {{ this.userDataInfo.id_nama_emp.label }}</h4>
        </div>
        <b-form class="mt-1">
          <b-row>            
            <b-col cols="12" md="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="Bisnis Unit"
                rules="required"
              >
                <b-form-group label="Bisnis Unit" label-for="bu" :state="errors.length > 0 ? false:null">
                  <v-select
                    v-model="userDataInfo.div_bu"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="buOptions"
                    :clearable="false"
                    input-id="bu"
                    placeholder="Pilih Bisnis Unit"
                    @input="changedValue()"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <validation-provider
                #default="{ errors }"
                name="Divisi"
                rules="required"
              >
                <b-form-group label="Divisi" label-for="divisi" :state="errors.length > 0 ? false:null">
                  <v-select
                    v-model="userDataInfo.div_name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="divOptions[0]"
                    :clearable="false"
                    input-id="divisi"
                    placeholder="Pilih Divisi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <validation-provider
                #default="{ errors }"
                name="Segmen"
                rules="required"
              >
                <b-form-group label="Segmen" label-for="segmen" :state="errors.length > 0 ? false:null">
                  <v-select
                    v-model="userDataInfo.segmen_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="segOptions"
                    :clearable="false"
                    input-id="segmen"
                    placeholder="Pilih Segmen"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Save
              </b-button>

              <b-button
                variant="outline-secondary"
                :to="{ name: 'apps-mapping-user-list'}"
              >
                Back
              </b-button>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th class="text-center">No</b-th>
                  <b-th>NIK</b-th>
                  <b-th>Nama</b-th>
                  <b-th>Bisnis Unit</b-th>
                  <b-th>Divisi</b-th>
                  <b-th>Segmen</b-th>
                  <b-th>Note</b-th>
                  <b-th class="text-center">Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(val,idx) in tbl_step2" :key="idx">
                  <b-td class="text-center">{{ idx+1 }}</b-td>
                  <b-td>{{ val.map_user_nik }}</b-td>
                  <b-td>{{ val.map_user_name }}</b-td>
                  <b-td>{{ val.nama_bu }}</b-td>
                  <b-td class="text-left">{{ val.div_desc }}</b-td>
                  <b-td class="text-left">{{ val.segmen_name }}</b-td>
                  <!-- <b-td class="text-left">{{ val.map_div_note == '0' ? 'Belum disetting di mapping divisi' : 'OK' }}</b-td> -->
                  <b-td class="text-left" v-if="val.map_div_note == 0"><span style="color:red">Belum disetting di mapping divisi</span></b-td>
                  <b-td class="text-left" v-if="val.map_div_note != 0"><span style="color:green">Sudah disetting di mapping divisi</span></b-td>
                  <b-td class="text-center"><feather-icon icon="TrashIcon" @click="deleteDetail(val.map_user_pk, val.div_bu, val.div_name, val.segmen_code)"/></b-td>
                </b-tr>
                <b-tr v-if="tbl_step2.length < 1">
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td>No records found</b-td>
                  <b-td class="text-left"></b-td>
                  <b-td class="text-right"></b-td>
                  <b-td class="text-right"></b-td>
                </b-tr>
              </b-tbody>
              <!-- <b-tfoot>
              <b-tr>
                <b-td class="text-center" colspan="4"> &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Total</strong>
                  </b-td>
                  <b-td class="text-left"><strong>{{ tbl_sum_vol }}</strong></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_std, {}) }}</strong></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_req, {}) }}</strong></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_harga_selisih, {}) }}</strong></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                </b-tr>
              </b-tfoot> -->
            </b-table-simple>
          </b-row>
        </b-form>
      </b-card>

    </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tbl_step2: [],
      // divOptions: [],
    }
  },
  created: function(){
    // alert(this.userDataInfo.)
    // console.log(this.userDataInfo.map_user_pk_old);
    this.load_table()
  },
  methods: {
    async changedValue() {
      const selectedd = JSON.stringify(this.userDataInfo.div_bu)
      console.log(selectedd);
      const result = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: selectedd })
      const dataDiv = result.data[0].divisi_by_bu
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataDiv.length; i < len; i++) {
        combo.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
      }
      this.divOptions = []
      this.divOptions.push(combo)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'add_detail_mapping_user')
          params.append('map_user_pk_old', this.userDataInfo.map_user_pk_old)
          params.append('map_user_pk', JSON.stringify(this.userDataInfo.id_nama_emp))
          // params.append('div_bu_old', this.userDataInfo.div_bu_old)
          params.append('div_bu', JSON.stringify(this.userDataInfo.div_bu))
          // params.append('div_name_old', this.userDataInfo.div_name_old)
          params.append('div_name', JSON.stringify(this.userDataInfo.div_name))
          // params.append('segmen_code_old', this.userDataInfo.segmen_code_old)
          params.append('segmen_code', JSON.stringify(this.userDataInfo.segmen_code))
          params.append('map_user_role', this.userDataInfo.map_user_role)
          params.append('hakakses_name', JSON.stringify(this.userDataInfo.hakakses_name))
          params.append('map_user_status', this.userDataInfo.map_user_status)
          params.append('user_proses', localStorage.getItem('emp_id'))
          console.log(JSON.stringify(this.userDataInfo.id_nama_emp))
          console.log(JSON.stringify(this.userDataInfo.div_desc))
          const result = await axios.post(`${useAppConfig().AppUrl}mst_mapping_user/add_detail`, params)

          if (result.data.message === 'Berhasil menambah data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            // this.$router.push('/apps/mapping-user/list')
            this.load_table()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan',
                variant: 'danger',
              },
            })
            // console.log(result)
          }
        }
      })
    },
    async load_table() {
      const result_table0 = await axios.post(`${useAppConfig().AppUrl}mst_mapping_user/tbl_detail`, {map_user_pk: router.currentRoute.params.map_user_pk})
      console.log(result_table0.data[0][1]);
      // let tbl_stp2 = JSON.stringify(result_table0[0])

      this.tbl_step2 = result_table0.data[0]
    },
    async deleteDetail(id, bu, div, seg) {
      this.$bvModal.msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
        title: 'Konfirmasi',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(async value => {
        // alert(value)
        this.boxTwo = value
        if (value === true) {
          const params = new URLSearchParams()
          params.append('param', 'delete')
          params.append('map_user_pk', id)
          params.append('div_bu', bu)
          params.append('div_name', div)
          params.append('segmen_code', seg)

          const result = await axios.post(`${useAppConfig().AppUrl}mst_mapping_user/delete_detail`, params)
          if (result.data.message === 'Berhasil menghapus data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                text: result.data.message,
                variant: 'success',
              },
            })
            this.load_table()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: 'Gagal menghapus data!',
                variant: 'danger',
              },
            })
          }
        }
      })
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })
    // const userDataInfo = ref({
    //   nama_bu: '',
    //   map_user_nik: '',
    //   div_name: '',
    //   map_user_role: '',
    //   hakakses_name: '',
    //   map_user_status: 1,

    // })
    const userDataInfo = ref({})
    const divOptions = ref([])

    onMounted(async () => {
      // const result = await axios.post(`${useAppConfig().AppUrl}mst_mapping_user/edit`, {map_user_pk: router.currentRoute.params.map_user_pk})
      const paramsEdit = new URLSearchParams()
            paramsEdit.append('map_user_pk', router.currentRoute.params.map_user_pk)
        const result = await axios.get(`${useAppConfig().AppUrl}mst_mapping_user/edit?${paramsEdit}`)
      userDataInfo.value = result.data[0]
      // console.log( userDataInfo.value)

      // const resultDiv = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: JSON.stringify(userDataInfo.value.div_bu) })
      // const dataDiv = resultDiv.data[0].divisi_by_bu
      // const comboDiv = []
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0, len = dataDiv.length; i < len; i++) {
      //   comboDiv.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
      // }
      // // this.divOptions = []
      // // console.log(comboDiv);
      // // this.divOptions.push(comboDiv)
      // divOptions.value = [comboDiv]
    })

    const buOptions = ref([])
    const namaOptions = ref([])
    const segOptions = ref([])
    
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const resultEmp = await axios.get(`${useAppConfig().AppUrl}global/listEmployee`)
      const dataNama = resultEmp.data[0].emp
      const dataBisnisunit = result.data[0].bu
      const dataSegmen = result.data[0].segmen
      const combo = []
      const combo1 = []
      const comboSegmen = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataNama.length; i < len; i++) {
        combo.push({ label: dataNama[i].id_nama_emp, value: dataNama[i].id })
      }
      for (let i = 0, len = dataBisnisunit.length; i < len; i++) {
        combo1.push({ label: dataBisnisunit[i].nama_bu, value: dataBisnisunit[i].id_bu })
      }
      for (let i = 0, len = dataSegmen.length; i < len; i++) {
        comboSegmen.push({ label: dataSegmen[i].segmen_name, value: dataSegmen[i].segmen_code })
      }
      namaOptions.value = combo
      buOptions.value = combo1
      segOptions.value = comboSegmen
    })

    const aksesOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const dataAkses = result.data[0].hakakses
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAkses.length; i < len; i++) {
        combo.push({ label: dataAkses[i].hakakses_desc, value: dataAkses[i].hakakses_name })
      }
      aksesOptions.value = combo
    })


    const rolOptions = [
      'Admin',
      'Sales',
      'Approval Sales',
      'Approval Divisi',
      'View Sales',
      'View All'
    ]

    const aktifOptions = [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ]

     return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      userDataInfo,
      aktifOptions,
      userDataInfo,
      buOptions,
      namaOptions,
      rolOptions,
      aksesOptions,
      codeVariant,
      divOptions,
      segOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
